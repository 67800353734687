import engine from 'store/src/store-engine';
import expirePlugin from 'store/plugins/expire';
import _localStorage from 'store/storages/localStorage';
import _sessionStorage from 'store/storages/sessionStorage';
import _cookieStorage from 'store/storages/cookieStorage';
import _memoryStorage from 'store/storages/memoryStorage';

export const localStorage = engine.createStore(
  [_localStorage, _cookieStorage, _memoryStorage],
  [expirePlugin],
);
export const sessionStorage = engine.createStore(
  [_sessionStorage, _cookieStorage, _memoryStorage],
  [expirePlugin],
);

export default {
  get(...args) {
    return localStorage.get(...args) || sessionStorage.get(...args);
  },
  set(...args) {
    localStorage.set(...args);
    sessionStorage.set(...args);
  },
  remove(...args) {
    localStorage.remove(...args);
    sessionStorage.remove(...args);
  },
  clearAll(...args) {
    localStorage.clearAll(...args);
    sessionStorage.clearAll(...args);
  },
};
