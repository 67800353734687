import { list, get, create, update, remove } from '../services/server';

export default {
  name: 'server',
  state: {
    query: {},
    items: [],
    meta: {},
    data: {},
  },
  reducers: {
    setState(state, payload) {
      return { ...state, ...payload };
    },
    setQuery(state, payload) {
      return { ...state, query: payload };
    },
  },
  effects: dispatch => ({
    async relist(payload, { server: { query: prevQuery } }) {
      const result = await dispatch.server.list({ ...prevQuery, ...payload });
      return result;
    },
    async list(payload = {}) {
      // 设置 query
      dispatch.server.setQuery(payload);

      // 发起请求
      const data = await list(payload);

      // 更新 state
      dispatch.server.setState({
        items: data.items,
        meta: data.meta,
      });

      return data;
    },
    async get(id) {
      const data = await get(id);
      dispatch.server.setState({ data });
      return data;
    },
    async create(payload) {
      const data = await create(payload);
      return data;
    },
    async update(payload) {
      const data = await update(payload.id, payload);
      return data;
    },
    async remove(payload) {
      const data = await remove(payload);
      return data;
    },
  }),
};
