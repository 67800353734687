import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { find } from 'lodash';
import * as gb2260 from 'gb2260';
import { Row, Col, Form, Input, Cascader, Select, Button, Popconfirm } from 'antd';
import Page from '../../layouts/Page';
import { regMobile } from '../../utils/validate';

const FormItem = Form.Item;
const mapAreaData = (item, isLeaf) => ({
  value: item.code,
  label: item.name,
  isLeaf
})
gb2260.register('201607', require('gb2260/lib/201607'));
const gb = new gb2260.GB2260();
const provinces = gb.provinces().map(item => mapAreaData(item, false));

class ClientForm extends React.PureComponent {
  state = {
    areaData: provinces
  }

  componentDidMount() {
    const { get, id, listAgent, listServer } = this.props;
    if (id) get(id);

    listAgent({ groupId: 10, size: 9999 });
    listServer({ size: 9999 })
  }

  remove = async () => {
    const { id, remove } = this.props;
    await remove(id);
    window.history.back();
  }

  loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let children = [];
    if (selectedOptions.length === 1) {
      children = gb.prefectures(targetOption.value).map(item => mapAreaData(item, false));
    } else if (selectedOptions.length === 2) {
      children = gb.counties(targetOption.value).map(item => mapAreaData(item));
    }

    targetOption.loading = false;
    targetOption.children = children;
    this.setState({
      areaData: [...this.state.areaData],
    });
  }

  handleOk = (e) => {
    e.preventDefault();
    const { form: { validateFields }, id, create, update } = this.props;
    validateFields(async (errors, values) => {
      if (errors) return;
      const { address, ...others } = values;
      const formData = {
        id,
        province: address && gb.get(address[0]).name,
        city: address && gb.get(address[1]).name,
        county: address && gb.get(address[2]).name,
        ...others,
      };

      await (id ? update : create)(formData);
      window.history.back();
    });
  }

  render() {
    const {
      id,
      edit,
      data,
      loading,
      agents,
      servers,
      form: { getFieldDecorator },
    } = this.props;
    const { areaData } = this.state;

    const action = !edit ? (
      <div>
        <Link to="update"><Button type="primary">编辑</Button></Link>
        <Popconfirm title="确定要删除吗？" onConfirm={this.remove} placement="bottomLeft">
          <Button className="ml10" type="danger">删除</Button>
        </Popconfirm>
      </div>
    ) : null;

    return (
      <Page sub action={action}>
        <Form onSubmit={edit && this.handleOk}>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="门店名称">
                {edit ? (
                  getFieldDecorator('shopName', {
                    initialValue: data.shopName,
                    rules: [{ required: false, message: '请输入门店名称' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.shopName || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="客户名称">
                {edit ? (
                  getFieldDecorator('clientName', {
                    initialValue: data.clientName,
                    rules: [{ required: false, message: '请输入客户名称' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.clientName || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="管理员姓名">
                {edit ? (
                  getFieldDecorator('name', {
                    initialValue: data.name,
                    rules: [{ required: false, message: '请输入管理员姓名' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.name || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="管理员手机号">
                {edit ? (
                  getFieldDecorator('mobile', {
                    initialValue: data.mobile,
                    rules: [
                      { required: false, message: '请输入管理员手机号' },
                      { pattern: regMobile, message: '管理员手机号不正确' },
                    ],
                    validateFirst: true,
                  })(<Input placeholder="请输入" />)
                ) : (data.mobile || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="初始密码">
                {edit ? (
                  getFieldDecorator('initPassword', {
                    initialValue: data.initPassword,
                    rules: [{ required: !id, message: '请输入初始密码' }],
                  })(<Input placeholder="请输入" disabled={!!id} />)
                ) : (data.initPassword || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="所在地" help={(id && edit) ? (data.province || '') + (data.city || '') + (data.county || '') : ''}>
                {edit ? (
                  getFieldDecorator('address', {
                    initialValue: data.address,
                    rules: [{ required: false, message: '请选择所在地' }],
                  })(<Cascader options={areaData} loadData={this.loadData} placeholder="请选择" />)
                ) : ((data.province + data.city + data.county) || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="代理商">
                {edit ? (
                  getFieldDecorator('agentId', {
                    initialValue: data.agentId || undefined,
                    rules: [{ required: false, message: '请输入代理商ID' }],
                  })(
                    <Select placeholder="请选择">
                    {agents.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                    </Select>
                  )
                ) : ((find(agents, { id: data.agentId }) || {}).name || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="服务器ID">
                {edit ? (
                  getFieldDecorator('serverId', {
                    initialValue: data.serverId,
                    rules: [{ required: false, message: '请输入服务器ID' }],
                  })(
                    <Select placeholder="请选择">
                      {servers.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                    </Select>
                  )
                ) : ((find(servers, { id: data.serverId }) || {}).name || '-')}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="备注">
                {edit ? (
                  getFieldDecorator('remark', {
                    initialValue: data.remark,
                  })(<Input.TextArea rows={4} />)
                ) : (data.remark || '-')}
              </FormItem>
            </Col>
          </Row>

          {edit
            ? <Button className="mt20" size="large" type="primary" htmlType="submit" loading={loading}>保存</Button>
            : null}
        </Form>
      </Page>
    );
  }
}

export default Form.create()(
  connect(
    (state, { id }) => ({
      loading: state.loading.models.client,
      data: id ? state.client.data : {},
      agents: state.user.items,
      servers: state.server.items,
    }),
    dispatch => ({
      get: dispatch.client.get,
      create: dispatch.client.create,
      update: dispatch.client.update,
      remove: dispatch.client.remove,
      listAgent: dispatch.user.list,
      listServer: dispatch.server.list,
    }),
  )(ClientForm),
);
