import React from 'react';
import moment from 'moment';
import { Table, Switch } from 'antd';

export default ({
  columns = ['id', 'admin', 'address', 'clientId', 'serviceAt', 'smsCount', 'smsSign', 'serverId', 'wechatName', 'status','remark'],
  renderOperation,
  update,
  ...others
}) => {
  const enumColumns = {
    id: {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    admin: {
      title: '客户管理员',
      dataIndex: 'admin',
      key: 'admin',
      render: (value, { name, mobile }) => (
        <div>
          {name} <br />
          <small>{mobile}</small>
        </div>
      ),
    },
    address: {
      title: '客户所在地',
      dataIndex: 'address',
      key: 'address',
      render: (value, { province, city, county }) => province + city + county,
    },
    clientId: {
      title: '客户ID',
      dataIndex: 'clientId',
      key: 'clientId',
      render: value => value || '-',
    },
    serviceAt: {
      title: '服务到期时间',
      dataIndex: 'serviceAt',
      key: 'serviceAt',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
    },
    smsCount: {
      title: '短信数量',
      dataIndex: 'smsCount',
      key: 'smsCount',
      render: value => value,
    },
    smsSign: {
      title: '短信签名',
      dataIndex: 'smsSign',
      key: 'smsSign',
      render: value => value || '-',
    },
    quota: {
      title: '最大学校数量',
      dataIndex: 'quota',
      key: 'quota',
      render: value => value || '-',
    },
    serverId: {
      title: '服务器ID',
      dataIndex: 'serverId',
      key: 'serverId',
      render: value => value || '-',
    },
    remark: {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      render: value => value || '-',
    },
    wechatName: {
      title: '授权公众号',
      dataIndex: 'wechatName',
      key: 'wechatName',
      render: value => value || '-',
    },
    status: {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (value, { id }) => (
        <Switch
          checked={!!value}
          onChange={checked => update({ id, status: checked ? 100 : 0 })}
        />
      ),
    },
    createdAt: {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
    },
    operation: {
      title: '操作',
      dataIndex: 'id',
      key: 'operation',
      render: (...args) => renderOperation(...args),
    },
  };

  return (
    <Table
      rowKey={record => record.id}
      columns={(renderOperation ? columns.concat(['operation']) : columns)
        .map(column => enumColumns[column])}
      {...others}
    />
  );
};
