import { navigate } from '@reach/router';
import services from '../services';
import storage, { localStorage, sessionStorage } from '../storage';

export default {
  name: 'auth',
  state: {
    authed: !!storage.get('auth'),
  },
  reducers: {
    setState(state, payload) {
      return { ...state, ...payload };
    },
  },
  effects: dispatch => ({
    async login({ remember, ...values }) {
      const data = await services.auth.login(values);

      if (remember) {
        localStorage.set('auth', data);
      } else {
        sessionStorage.set('auth', data);
      }

      dispatch.auth.setState({ authed: true });

      return data;
    },
    logout() {
      storage.clearAll();
      dispatch.auth.setState({ authed: false });
      navigate('/');
    },
    init() {
      console.log('init');
    },
  }),
};
