import React from 'react';
import { Router } from '@reach/router';
import DocumentTitle from 'react-document-title';
import find from 'lodash/find';
import omit from 'lodash/omit';
import styles from './AuthLayout.module.less';
import { getRouteData } from '../nav';
import { PRODUCT_NAME } from '../constant';

class AuthLayout extends React.PureComponent {
  routes = getRouteData('AuthLayout')()

  getPageTitle(pathname) {
    const route = find(this.routes, { path: pathname === '/' ? pathname : pathname.substr(1) });
    return route ? `${route.name} - ${PRODUCT_NAME}` : PRODUCT_NAME;
  }

  render() {
    const { location: { pathname } } = this.props;
    return (
      <DocumentTitle title={this.getPageTitle(pathname)}>
        <div className={styles.container}>
          <Router>
            {this.routes.map(item => (
              <item.component key={item.path} {...omit(item, ['component'])} />
            ))}
          </Router>
        </div>
      </DocumentTitle>
    );
  }
}

export default AuthLayout;
