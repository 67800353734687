import { Redirect } from '@reach/router';

import cloneDeep from 'lodash/cloneDeep';
import Login from './containers/Auth/Login';

import UserList from './containers/User/List';
import UserForm from './containers/User/Form';

import ServerList from './containers/Server/List';
import ServerForm from './containers/Server/Form';

import ClientList from './containers/Client/List';
import ClientForm from './containers/Client/Form';

import AppList from './containers/App/List';
import AppForm from './containers/App/Form';

/* eslint-disable global-require */
const navData = {
  AuthLayout: [
    { name: '登录', path: '*', component: Login },
  ],
  BasicLayout: {
    100: [
      { path: '/', component: Redirect, from: '/', to: 'client', noThrow: true },
      {
        name: '客户',
        path: 'client',
        component: ClientList,
        icon: 'contacts',
        menu: true,
        children: [
          { name: '新增客户', path: 'create', component: ClientForm, edit: true },
          { name: '编辑客户', path: ':id/update', component: ClientForm, edit: true },
          { name: 'App 列表', path: ':clientId/app', component: AppList },
          { name: '客户详情', path: ':id', component: ClientForm },
        ],
      },
      {
        name: 'App',
        path: 'app',
        component: AppList,
        icon: 'tablet',
        menu: true,
        children: [
          { name: '新增App', path: 'create', component: AppForm, edit: true },
          { name: '编辑App', path: ':id/update', component: AppForm, edit: true },
          { name: 'App详情', path: ':id', component: AppForm },
        ],
      },
      {
        name: '服务器',
        path: 'server',
        component: ServerList,
        icon: 'cloud',
        menu: true,
        children: [
          { name: '新增服务器', path: 'create', component: ServerForm, edit: true },
          { name: '编辑服务器', path: ':id/update', component: ServerForm, edit: true },
          { name: '服务器详情', path: ':id', component: ServerForm },
        ],
      },
      {
        name: '代理商',
        path: 'agent',
        component: UserList,
        icon: 'team',
        menu: true,
        children: [
          { name: '新增代理商', path: 'create', component: UserForm, edit: true },
          { name: '编辑代理商', path: ':id/update', component: UserForm, edit: true },
          { name: '代理商详情', path: ':id', component: UserForm },
        ],
      },
      {
        name: '管理员',
        path: 'admin',
        component: UserList,
        icon: 'user',
        menu: true,
        children: [
          { name: '新增管理员', path: 'create', component: UserForm, edit: true },
          { name: '编辑管理员', path: ':id/update', component: UserForm, edit: true },
          { name: '管理员详情', path: ':id', component: UserForm },
        ],
      },
    ],
  },
};
/* eslint-enable global-require */

export function getMenus(layout) {
  return (roleId) => {
    let dataList = navData[layout];
    if (Object.prototype.toString.call(dataList) === '[object Object]') {
      dataList = dataList[roleId];
    }
    return filterMenus(dataList) || [];
  };
}

export function getRouteData(layout) {
  return (roleId) => {
    let dataList = navData[layout];
    if (Object.prototype.toString.call(dataList) === '[object Object]') {
      dataList = dataList[roleId];
    }
    dataList = cloneDeep(dataList);
    return getPlainNode(dataList || []);
  };
}

function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach((node) => {
    if (node.component) {
      node.path = `${parentPath}${parentPath && '/'}${node.path}`;
      arr.push(node);
    }
    if (node.children) {
      arr.push(...getPlainNode(node.children, node.path));
    }
  });
  return arr;
}

function filterMenus(menus) {
  if (!menus) return undefined;

  const _menus = menus.filter(item => item.menu);
  if (!_menus.length) return undefined;

  return _menus.map(item => ({
    ...item,
    children: filterMenus(item.children),
  }));
}
