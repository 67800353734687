import { stringify } from 'qs';
import request from '../utils/request';

const MODEL = '/admin/client';

export async function list(params) {
  return request(`${MODEL}?${stringify(params)}`);
}

export async function get(id, params) {
  return request(`${MODEL}/${id}?${stringify(params)}`);
}

export async function create(params) {
  return request(`${MODEL}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
}

export async function update(id, params) {
  return request(`${MODEL}/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
}

export async function remove(ids) {
  return request(`${MODEL}/${ids}`, {
    method: 'delete',
  });
}
