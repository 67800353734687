import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, Input, Select, Cascader } from 'antd';
import * as gb2260 from 'gb2260';
import Page from '../../layouts/Page';
import { mapMetaToPagination, mapPaginationToQuery } from '../../utils/helper';
import Table from './Table';

const mapAreaData = (item, isLeaf) => ({
  value: item.code,
  label: item.name,
  isLeaf
})
gb2260.register('201607', require('gb2260/lib/201607'));
const gb = new gb2260.GB2260();
const provinces = gb.provinces().map(item => mapAreaData(item, false));

class ClientList extends React.PureComponent {
  state = {
    areaData: provinces
  }

  componentDidMount() {
    const { list, relist, listServer } = this.props;
    listServer({ size: 9999 });
    (!window.isPop ? list : relist)();
  }

  handlePaginationChange = (pagination, filters) => {
    const { relist } = this.props;
    relist({
      ...mapPaginationToQuery(pagination),
      ...filters,
    });
  }

  loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;

    let children = [];
    if (selectedOptions.length === 1) {
      children = gb.prefectures(targetOption.value).map(item => mapAreaData(item, false));
    } else if (selectedOptions.length === 2) {
      children = gb.counties(targetOption.value).map(item => mapAreaData(item));
    }

    targetOption.loading = false;
    targetOption.children = children;
    this.setState({
      areaData: [...this.state.areaData],
    });
  }

  remove = async (id) => {
    const { remove, relist } = this.props;
    await remove(id);
    relist();
  }

  renderOperation = id => (
    <div>
      <Link to={`${id}/app`}>App 列表</Link>
      <Divider type="vertical" />
      <Link to={`${id}`}>查看</Link>
      <Divider type="vertical" />
      <Link to={`${id}/update`}>修改</Link>
      <Divider type="vertical" />
      <Popconfirm title="确定要删除吗？" onConfirm={() => this.remove(id)}>
        <a href="#">删除</a>
      </Popconfirm>
    </div>
  )

  render() {
    const { loading, dataSource, pagination, serverList, update, relist } = this.props;
    const { areaData } = this.state;

    const tableProps = {
      loading,
      dataSource,
      pagination,
      onChange: this.handlePaginationChange,
      renderOperation: this.renderOperation,
      update: async (...args) => {
        await update(...args);
        relist();
      },
    };

    return (
      <Page>
        <div className="mb20">
          <Link to="create"><Button type="primary">新增</Button></Link>
          <Select
            style={{ width: '250px', marginLeft: '20px' }}
            placeholder="所属服务器"
            onChange={serverId => relist({ serverId, page: undefined })}
            allowClear
          >
            {serverList.map(item => <Select.Option key={item.id} value={item.id}>{item.name} {item.id}</Select.Option>)}
          </Select>
          <Cascader
            options={areaData}
            loadData={this.loadData}
            placeholder="省市县"
            changeOnSelect
            onChange={([province, city, county]) => relist({ province: province && gb.get(province).name, city: city && gb.get(city).name, county: county && gb.get(county).name, page: undefined })}
            style={{ width: '250px', marginLeft: '20px' }}
          />
          <Input.Search
            placeholder="输入关键字搜索"
            onSearch={keyword => relist({ keyword, page: undefined })}
            style={{ width: '250px', marginLeft: '20px' }}
            enterButton
            allowClear
          />
        </div>
        <Table {...tableProps} />
      </Page>
    );
  }
}

export default connect(
  state => ({
    loading: state.loading.effects.client.list,
    query: state.client.query,
    dataSource: state.client.items,
    pagination: mapMetaToPagination(state.client.meta),
    serverList: state.server.items,
  }),
  dispatch => ({
    list: dispatch.client.list,
    relist: dispatch.client.relist,
    update: dispatch.client.update,
    remove: dispatch.client.remove,
    listServer: dispatch.server.list,
  }),
)(ClientList);
