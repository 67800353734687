import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { find, unionBy } from 'lodash';
import moment from 'moment';
import { Row, Col, Form, Input, InputNumber, DatePicker, Select, Button, Popconfirm, Divider, Switch } from 'antd';
import Page from '../../layouts/Page';

const FormItem = Form.Item;

class AppForm extends React.PureComponent {
  componentDidMount() {
    const { get, id, listClient } = this.props;
    if (id) get(id);

    listClient();
  }

  remove = async () => {
    const { id, remove } = this.props;
    await remove(id);
    window.history.back();
  }

  handleOk = (e) => {
    e.preventDefault();
    const { form: { validateFields }, id, create, update, clients } = this.props;
    validateFields(async (errors, values) => {
      if (errors) return;
      const client = find(clients, { id: values.clientId }) || {};
      const formData = {
        id,
        ...values,
        serviceAt: values.serviceAt && values.serviceAt.format('YYYY-MM-DD[T]HH:mm:ss'),
        wechatOfficialAccount: +values.wechatOfficialAccount,
        name: client.shopName,
        province: client.province,
        city: client.city,
        county: client.county,
        serverId: client.serverId,
      };

      await (id ? update : create)(formData);
      window.history.back();
    });
  }

  render() {
    const {
      edit,
      data,
      loading,
      clients,
      listClient,
      form: { getFieldDecorator },
    } = this.props;


    const action = !edit ? (
      <div>
        <Link to="update"><Button type="primary">编辑</Button></Link>
        <Popconfirm title="确定要删除吗？" onConfirm={this.remove} placement="bottomLeft">
          <Button className="ml10" type="danger">删除</Button>
        </Popconfirm>
      </div>
    ) : null;

    return (
      <Page sub action={action}>
        <Form onSubmit={edit && this.handleOk}>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="客户">
                {edit ? (
                  getFieldDecorator('clientId', {
                    initialValue: data.clientId,
                    rules: [{ required: false, message: '请选择客户' }],
                  })(
                    <Select
                      showSearch
                      placeholder="请选择，可搜索"
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={keyword => listClient({ keyword })}
                    >
                      {unionBy(data.clientId ? [{ id: data.clientId, name: data.name }] : [], clients.map(item => ({ id: item.id, name: item.shopName })), 'id').map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                    </Select>
                  )
                ) : `${data.name || '-'} ${data.province || ''}${data.city || ''}${data.county || ''}`}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="是否开通微信公众号">
                {edit ? (
                  getFieldDecorator('wechatOfficialAccount', {
                    initialValue: !!data.wechatOfficialAccount,
                    valuePropName: 'checked'
                  })(<Switch />)
                ) : (data.wechatOfficialAccount ? '是' : '否')}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="设备数量">
                {edit ? (
                  getFieldDecorator('deviceCount', {
                    initialValue: data.deviceCount,
                    rules: [{ required: false, message: '请输入设备数量' }],
                  })(<InputNumber precision={0} placeholder="请输入" style={{ width: '100%' }} />)
                ) : (data.deviceCount || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="服务到期时间">
                {edit ? (
                  getFieldDecorator('serviceAt', {
                    initialValue: data.serviceAt && moment(data.serviceAt),
                    rules: [{ required: false, message: '请输入服务到期时间' }],
                  })(<DatePicker style={{ width: '100%' }} />)
                ) : ((data.serviceAt && moment(data.serviceAt).format('YYYY-MM-DD')) || '-')}
              </FormItem>
            </Col>
            {/* <Col span={8}>
              <FormItem label="最大学校数量">
                {edit ? (
                  getFieldDecorator('quota', {
                    initialValue: data.quota,
                    rules: [{ required: false, message: '请输入最大学校数量' }],
                  })(<InputNumber precision={0} placeholder="请输入" style={{ width: '100%' }} />)
                ) : (data.quota || '-')}
              </FormItem>
            </Col> */}
          </Row>
          <Divider orientation="left">短信服务</Divider>
          <Row gutter={30}>
            {/* <Col span={8}>
              <FormItem label="短信数量">
                {edit ? (
                  getFieldDecorator('smsCount', {
                    initialValue: data.smsCount,
                    rules: [{ required: false, message: '请输入短信数量' }],
                  })(<InputNumber precision={0} placeholder="请输入" style={{ width: '100%' }} />)
                ) : (data.smsCount || '-')}
              </FormItem>
            </Col> */}
            <Col span={8}>
              <FormItem label="短信签名">
                {edit ? (
                  getFieldDecorator('smsSign', {
                    initialValue: data.smsSign,
                    rules: [{ required: false, message: '请输入短信签名' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.smsSign || '-')}
              </FormItem>
            </Col>
          </Row>
          {/* <Row gutter={30}>
            <Col span={8}>
              <FormItem label="短信帐户">
                {edit ? (
                  getFieldDecorator('smsAccount', {
                    initialValue: data.smsAccount,
                    rules: [{ required: false, message: '请输入短信帐户' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.smsAccount || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="短信密码">
                {edit ? (
                  getFieldDecorator('smsPassword', {
                    initialValue: data.smsPassword,
                    rules: [{ required: false, message: '请输入短信密码' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.smsPassword || '-')}
              </FormItem>
            </Col>
          </Row> */}
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="备注">
                {edit ? (
                  getFieldDecorator('remark', {
                    initialValue: data.remark,
                  })(<Input.TextArea rows={4} />)
                ) : (data.remark || '-')}
              </FormItem>
            </Col>
          </Row>

          {edit
            ? <Button className="mt20" size="large" type="primary" htmlType="submit" loading={loading}>保存</Button>
            : null}
        </Form>
      </Page>
    );
  }
}

export default Form.create()(
  connect(
    (state, { id }) => ({
      loading: state.loading.models.app,
      data: id ? state.app.data : {},
      clients: state.client.items,
    }),
    dispatch => ({
      get: dispatch.app.get,
      create: dispatch.app.create,
      update: dispatch.app.update,
      remove: dispatch.app.remove,
      listClient: dispatch.client.list,
    }),
  )(AppForm),
);
