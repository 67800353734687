import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, Popconfirm } from 'antd';
import Page from '../../layouts/Page';

const FormItem = Form.Item;

class ServerForm extends React.PureComponent {
  componentDidMount() {
    const { get, id } = this.props;
    if (id) get(id);
  }

  remove = async () => {
    const { id, remove } = this.props;
    await remove(id);
    window.history.back();
  }

  handleOk = (e) => {
    e.preventDefault();
    const { form: { validateFields }, id, create, update } = this.props;
    validateFields(async (errors, values) => {
      if (errors) return;
      const formData = {
        id,
        ...values,
      };

      await (id ? update : create)(formData);
      window.history.back();
    });
  }

  render() {
    const {
      edit,
      data,
      loading,
      form: { getFieldDecorator },
    } = this.props;

    const action = !edit ? (
      <div>
        <Link to="update"><Button type="primary">编辑</Button></Link>
        <Popconfirm title="确定要删除吗？" onConfirm={this.remove} placement="bottomLeft">
          <Button className="ml10" type="danger">删除</Button>
        </Popconfirm>
      </div>
    ) : null;

    return (
      <Page sub action={action}>
        <Form onSubmit={edit && this.handleOk}>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="名称">
                {edit ? (
                  getFieldDecorator('name', {
                    initialValue: data.name,
                    rules: [{ required: false, message: '请输入名称' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.name || '-')}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="域名">
                {edit ? (
                  getFieldDecorator('domain', {
                    initialValue: data.domain,
                    rules: [{ required: false, message: '请输入域名' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.domain || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="端口">
                {edit ? (
                  getFieldDecorator('port', {
                    initialValue: data.port,
                    rules: [{ required: false, message: '请输入端口' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.port || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="最大容量">
                {edit ? (
                  getFieldDecorator('quota', {
                    initialValue: data.quota,
                    rules: [{ required: false, message: '请输入最大容量' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.quota || '-')}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="AppKey">
                {edit ? (
                  getFieldDecorator('appKey', {
                    initialValue: data.appKey,
                  })(<Input placeholder="请输入" />)
                ) : (data.appKey || '-')}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="AppSecret">
                {edit ? (
                  getFieldDecorator('appSecret', {
                    initialValue: data.appSecret,
                    validateFirst: true,
                  })(<Input placeholder="请输入" />)
                ) : (data.appSecret || '-')}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="备注">
                {edit ? (
                  getFieldDecorator('remark', {
                    initialValue: data.remark,
                  })(<Input.TextArea rows={4} />)
                ) : (data.remark || '-')}
              </FormItem>
            </Col>
          </Row>

          {edit
            ? <Button className="mt20" size="large" type="primary" htmlType="submit" loading={loading}>保存</Button>
            : null}
        </Form>
      </Page>
    );
  }
}

export default Form.create()(
  connect(
    (state, { id }) => ({
      loading: state.loading.models.server,
      data: id ? state.server.data : {},
    }),
    dispatch => ({
      get: dispatch.server.get,
      create: dispatch.server.create,
      update: dispatch.server.update,
      remove: dispatch.server.remove,
    }),
  )(ServerForm),
);
