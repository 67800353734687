import React from 'react';
import moment from 'moment';
import { Table, Switch } from 'antd';

export default ({
  columns = ['id', 'shopName', 'clientName', 'admin', 'address', 'remark', 'agentId', 'serverId', 'createdAt'],
  renderOperation,
  update,
  ...others
}) => {
  const enumColumns = {
    id: {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    shopName: {
      title: '门店名称',
      dataIndex: 'shopName',
      key: 'shopName',
      render: value => value || '-',
    },
    clientName: {
      title: '客户名称',
      dataIndex: 'clientName',
      key: 'clientName',
      render: value => value || '-',
    },
    admin: {
      title: '管理员',
      dataIndex: 'admin',
      key: 'admin',
      render: (value, { name, mobile }) => <div>{name || '-'} <br />{mobile || '-'}</div>,
    },
    address: {
      title: '所在地',
      dataIndex: 'address',
      key: 'address',
      render: (value, { province, city, county }) => (province + city + county) || '-',
    },
    remark: {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      width: 250,
      render: value => value || '-',
    },
    agentId: {
      title: '代理商ID',
      dataIndex: 'agentId',
      key: 'agentId',
      render: value => value || '-',
    },
    serverId: {
      title: '服务器ID',
      dataIndex: 'serverId',
      key: 'serverId',
      render: value => value || '-',
    },
    status: {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (value, { id }) => (
        <Switch
          checked={!!value}
          onChange={checked => update({ id, status: checked ? 100 : 0 })}
        />
      ),
    },
    createdAt: {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
    },
    operation: {
      title: '操作',
      dataIndex: 'id',
      key: 'operation',
      render: (...args) => renderOperation(...args),
    },
  };

  return (
    <Table
      rowKey={record => record.id}
      columns={(renderOperation ? columns.concat(['operation']) : columns)
        .map(column => enumColumns[column])}
      {...others}
    />
  );
};
