// Use require.context to require reducers automatically
// Ref: https://webpack.github.io/docs/context.html
const context = require.context('./', false, /\.js$/);
const keys = context.keys().filter(item => item !== './index.js');

const services = {};
for (let i = 0; i < keys.length; i += 1) {
  services[keys[i].slice(2, -3)] = context(keys[i]);
}

export default services;
