import React from 'react';
import moment from 'moment';
import { Table, Switch } from 'antd';

export default ({
  columns = ['id', 'name', 'mobile', 'remark', 'status', 'createdAt'],
  renderOperation,
  update,
  ...others
}) => {
  const enumColumns = {
    id: {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    name: {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: value => value || '-',
    },
    mobile: {
      title: '手机',
      dataIndex: 'mobile',
      key: 'mobile',
      render: value => value || '-',
    },
    remark: {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      render: value => value || '-',
    },
    status: {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (value, { id }) => (
        <Switch
          checked={!!value}
          onChange={checked => update({ id, status: checked ? 100 : 0 })}
        />
      ),
    },
    createdAt: {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => text ? moment(text).format('YYYY-MM-DD') : '-',
    },
    operation: {
      title: '操作',
      dataIndex: 'id',
      key: 'operation',
      render: (...args) => renderOperation(...args),
    },
  };

  return (
    <Table
      rowKey={record => record.id}
      columns={(renderOperation ? columns.concat(['operation']) : columns)
        .map(column => enumColumns[column])}
      {...others}
    />
  );
};
