export const regMobile = /^1[3456789][0-9]{9}$/;

export const regEmail = /^([\w-_]+(?:\.[\w-_]+)*)@((?:[a-z0-9]+(?:-[a-zA-Z0-9]+)*)+\.[a-z]{2,6})$/i;

export const regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

export const regUrl = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;

export function isMobile(value = '') {
  return regMobile.test(value);
}

export function isEmail(value = '') {
  return regEmail.test(value);
}

export function isId(value = '') {
  return regId.test(value);
}

export function isUrl(value = '') {
  return regUrl.test(value);
}
