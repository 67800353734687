import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider, Modal, Table, Input, message, Select } from 'antd';
import Page from '../../layouts/Page';
import { mapMetaToPagination, mapPaginationToQuery } from '../../utils/helper';
import AppTable from './Table';
import { listRechargeLog, createRechargeLog } from '../../services/app'

class AppList extends React.PureComponent {
  state = { aid: '', rechargeLogs: [], rechargeVisible: false }

  componentDidMount() {
    const { list, relist, listServer, clientId } = this.props;
    listServer({ size: 9999 });
    (!window.isPop ? list : relist)({ clientId });
  }

  handlePaginationChange = (pagination, filters) => {
    const { relist } = this.props;
    relist({
      ...mapPaginationToQuery(pagination),
      ...filters,
    });
  }

  remove = async (id) => {
    const { remove, relist } = this.props;
    await remove(id);
    relist();
  }

  copyWxAuthLink = (id) => {
    const link = `https://wx.qingpai365.com/wx/bauth?aid=${id}`
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', link);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
    }
    document.body.removeChild(input);
    message.success(`链接已复制：${link}`);
  }

  renderOperation = id => (
    <div>
      <Link to={`${id}`}>查看</Link>
      <Divider type="vertical" />
      <a onClick={() => this.openRechargeModal(id)}>充值</a>
      <Divider type="vertical" />
      <Link to={`${id}/update`}>修改</Link>
      <Divider type="vertical" />
      <Popconfirm title="确定要删除吗？" onConfirm={() => this.remove(id)}>
        <a href="#">删除</a>
      </Popconfirm>
      <Divider type="vertical" />
      <a onClick={() => this.copyWxAuthLink(id)}>微信授权链接</a>
    </div>
  )

  openRechargeModal = async (aid) => {
    this.setState({ aid, rechargeVisible: true })
    const data = await listRechargeLog({ aid })
    this.setState({ rechargeLogs: data })
  }
  closeRechargeModal = () => {
    this.setState({ aid: '', rechargeVisible: false })
  }
  createRechargeLog = async (value) => {
    await createRechargeLog({ appId: this.state.aid, count: +value })
    const data = await listRechargeLog({ aid: this.state.aid })
    this.setState({ rechargeLogs: data })
  }

  render() {
    const { loading, dataSource, pagination, serverList, update, relist } = this.props;
    const { rechargeLogs, rechargeVisible } = this.state;

    const tableProps = {
      loading,
      dataSource,
      pagination,
      onChange: this.handlePaginationChange,
      renderOperation: this.renderOperation,
      update: async (...args) => {
        await update(...args);
        relist();
      },
    };

    return (
      <Page>
        <div className="mb20">
          <Link to="create"><Button type="primary">新增</Button></Link>
          <Select
            style={{ width: '250px', marginLeft: '20px' }}
            placeholder="所属服务器"
            onChange={serverId => relist({ serverId, page: undefined })}
            allowClear
          >
            {serverList.map(item => <Select.Option key={item.id} value={item.id}>{item.name} {item.id}</Select.Option>)}
          </Select>
          <Input.Search
            placeholder="输入关键字搜索"
            onSearch={keyword => relist({ keyword, page: undefined })}
            style={{ width: '250px', marginLeft: '20px' }}
            enterButton
            allowClear
          />
        </div>
        <AppTable {...tableProps} />
        <Modal
          title="充值记录"
          visible={rechargeVisible}
          footer={null}
          onCancel={this.closeRechargeModal}
        >
          <Input.Search
            placeholder="请输入充值条数"
            enterButton="充值"
            onSearch={this.createRechargeLog}
            style={{ marginBottom: 20 }}
          />
          <Table
            size="small"
            columns={[
              { title: '充值条数', key: 'count', dataIndex: 'count' },
              { title: '充值时间', key: 'createdAt', dataIndex: 'createdAt', render: v => new Date(v).toLocaleString() },
            ]}
            dataSource={rechargeLogs}
            pagination={false}
          />
        </Modal>
      </Page>
    );
  }
}

export default connect(
  state => ({
    loading: state.loading.effects.app.list,
    query: state.app.query,
    dataSource: state.app.items,
    logs: state.app.logs,
    pagination: mapMetaToPagination(state.app.meta),
    serverList: state.server.items,
  }),
  dispatch => ({
    list: dispatch.app.list,
    relist: dispatch.app.relist,
    update: dispatch.app.update,
    remove: dispatch.app.remove,
    listRechargeLog: dispatch.app.listRechargeLog,
    listServer: dispatch.server.list,
    createRechargeLog: dispatch.app.createRechargeLog,
    setState: dispatch.app.setState,
  }),
)(AppList);
