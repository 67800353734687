import request from '../utils/request';

/**
 * 登录认证
 * @param {object} params { mobile, password }
 */
export async function login(params) {
  return request('/auth/admin', {
    method: 'post',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
}