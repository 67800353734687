import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Button, Divider, Popconfirm } from 'antd';
import Page from '../../layouts/Page';
import { mapMetaToPagination, mapPaginationToQuery } from '../../utils/helper';
import Table from './Table';

class UserList extends React.PureComponent {
  componentDidMount() {
    const { list, relist, location: { pathname } } = this.props;
    
    let groupId = 0;
    if (!pathname.indexOf('/admin')) {
      groupId = 100
    } else if (!pathname.indexOf('/agent')) {
      groupId = 10
    }
    (!window.isPop ? list : relist)({ groupId });
  }

  handlePaginationChange = (pagination, filters) => {
    const { relist } = this.props;
    relist({
      ...mapPaginationToQuery(pagination),
      ...filters,
    });
  }

  remove = async (id) => {
    const { remove, relist } = this.props;
    await remove(id);
    relist();
  }

  renderOperation = id => (
    <div>
      <Link to={`${id}/update`}>修改</Link>
      <Divider type="vertical" />
      <Popconfirm title="确定要删除吗？" onConfirm={() => this.remove(id)}>
        <a href="#">删除</a>
      </Popconfirm>
    </div>
  )

  render() {
    const { loading, dataSource, pagination, update, relist } = this.props;

    const tableProps = {
      loading,
      dataSource,
      pagination,
      onChange: this.handlePaginationChange,
      renderOperation: this.renderOperation,
      update: async (...args) => {
        await update(...args);
        relist();
      },
    };

    return (
      <Page>
        <div className="mb20"><Link to="create"><Button type="primary">新增</Button></Link></div>
        <Table {...tableProps} />
      </Page>
    );
  }
}

export default connect(
  state => ({
    loading: state.loading.effects.user.list,
    query: state.user.query,
    dataSource: state.user.items,
    pagination: mapMetaToPagination(state.user.meta),
  }),
  dispatch => ({
    list: dispatch.user.list,
    relist: dispatch.user.relist,
    update: dispatch.user.update,
    remove: dispatch.user.remove,
  }),
)(UserList);
