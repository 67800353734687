import { init } from '@rematch/core';
import loadingPlugin from '@rematch/loading';

import models from './models';

const loadingPluginOptions = {};

const store = init({
  plugins: [
    loadingPlugin(loadingPluginOptions),
  ],
  models,
});

window.store = store;

export default store;
