import React from 'react';
import { message } from 'antd';
import store from '../store';

let hide401 = null;
function logout() {
  store.dispatch.app.logout();
  hide401();
}

export default async (err) => {
  if (hide401) hide401();

  const { response } = err;

  // 特殊处理
  if (response && response.status === 401) {
    hide401 = message.error(<span>{err.msg}，<a onClick={logout}>重新登陆</a></span>, 0);
  } else {
    message.error(err.message || '发生未知错误');
  }

  throw err;
};
