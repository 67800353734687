import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Row, Col, Form, Input, Button, Popconfirm } from 'antd';
import Page from '../../layouts/Page';
import services from '../../services';
import { regMobile } from '../../utils/validate';

const FormItem = Form.Item;

class UserForm extends React.PureComponent {
  componentDidMount() {
    const { get, id } = this.props;
    if (id) get(id);
  }

  remove = async () => {
    const { id, remove } = this.props;
    await remove(id);
    window.history.back();
  }

  checkMobile = async (rule, value, callback) => {
    try {
      await services.user.check({ mobile: value });
      callback();
    } catch (err) {
      callback(err.msg);
    }
  }

  handleOk = (e) => {
    e.preventDefault();
    const { form: { validateFields }, id, create, update, location: { pathname } } = this.props;
    let groupId = 0;
    if (!pathname.indexOf('/admin')) {
      groupId = 100
    } else if (!pathname.indexOf('/agent')) {
      groupId = 10
    }

    validateFields(async (errors, values) => {
      if (errors) return;
      const formData = {
        id,
        groupId,
        ...values,
      };

      await (id ? update : create)(formData);
      window.history.back();
    });
  }

  render() {
    const {
      edit,
      data,
      loading,
      form: { getFieldDecorator },
    } = this.props;
    console.log(data);


    const action = !edit ? (
      <div>
        <Link to="update"><Button type="primary">编辑</Button></Link>
        <Popconfirm title="确定要删除吗？" onConfirm={this.remove} placement="bottomLeft">
          <Button className="ml10" type="danger">删除</Button>
        </Popconfirm>
      </div>
    ) : null;

    return (
      <Page sub action={action}>
        <Form onSubmit={edit && this.handleOk}>
          <Row gutter={30}>
            <Col span={8}>
              <FormItem label="姓名">
                {edit ? (
                  getFieldDecorator('name', {
                    initialValue: data.name,
                    rules: [{ required: false, message: '请输入姓名' }],
                  })(<Input placeholder="请输入" />)
                ) : (data.name || '-')}
              </FormItem>
              <FormItem label="手机号">
                {edit ? (
                  getFieldDecorator('mobile', {
                    initialValue: data.mobile,
                    rules: [
                      { required: false, message: '请输入手机号' },
                      { pattern: regMobile, message: '手机号不正确' },
                    ],
                    validateFirst: true,
                  })(<Input placeholder="请输入" />)
                ) : (data.mobile || '-')}
              </FormItem>
              <FormItem label="密码">
                {edit ? (
                  getFieldDecorator('password', {
                    initialValue: data.password,
                    rules: [{ required: false, message: '请输入密码' }],
                  })(<Input type="password" placeholder="请输入" />)
                ) : (data.password || '-')}
              </FormItem>
              <FormItem label="备注">
                {edit ? (
                  getFieldDecorator('remark', {
                    initialValue: data.remark,
                  })(<Input.TextArea rows={4} />)
                ) : (data.remark || '-')}
              </FormItem>
            </Col>
          </Row>

          {edit
            ? <Button className="mt20" size="large" type="primary" htmlType="submit" loading={loading}>保存</Button>
            : null}
        </Form>
      </Page>
    );
  }
}

export default Form.create()(
  connect(
    (state, { id }) => ({
      loading: state.loading.models.user,
      data: id ? state.user.data : {},
    }),
    dispatch => ({
      get: dispatch.user.get,
      create: dispatch.user.create,
      update: dispatch.user.update,
      remove: dispatch.user.remove,
    }),
  )(UserForm),
);
