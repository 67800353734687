import errHandler from './err_handler';
import storage from '../storage';
import { API } from '../constant';

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options = {}) {
  const { token } = storage.get('auth') || {};
  const newOptions = {
    ...options,
    headers: {
      Accept: 'application/json',
      ...token && { Authorization: `Bearer ${token}` },
      ...options.headers,
    },
  };

  return fetch(API + url, newOptions)
    .then(checkStatus)
    .then(parseJSON)
    .then((res) => {
      if (!res.code) return res.data;
      throw new Error(res.message);
    })
    .catch(errHandler);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

function parseJSON(response) {
  return response.json();
}
