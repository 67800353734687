import React from 'react';
import { Link } from '@reach/router';
import { Layout, Menu, Icon } from 'antd';
import { PRODUCT_NAME } from '../constant';

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class BasicSider extends React.PureComponent {
  state = { openKeys: null, selectedKeys: null }

  static getDerivedStateFromProps(props, state) {
    const { pathname } = props;
    return {
      openKeys: state.openKeys || getDefaultCollapsedSubMenus(pathname),
      selectedKeys: getCurrentMenuSelectedKeys(pathname),
    };
  }

  handleOpenChange = (openKeys) => {
    const { menus } = this.props;
    const lastOpenKey = openKeys[openKeys.length - 1];
    const isMainMenu = menus.some(item => `${item.path}` === lastOpenKey);
    this.setState({ openKeys: isMainMenu ? [lastOpenKey] : [...openKeys] });
  }

  render() {
    const { pathname, menus } = this.props;
    const { selectedKeys, openKeys } = this.state;

    return (
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
      >
        <h3 className="cf tc mtb2">{PRODUCT_NAME}</h3>
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={this.handleOpenChange}
        >
          {getNavMenuItems(pathname, menus)}
        </Menu>
      </Sider>
    );
  }
}

function getNavMenuItems(pathname, menus, parentPath = '') {
  return menus.map((item) => {
    let itemPath;
    if (item.path.indexOf('http') === 0) {
      itemPath = item.path;
    } else {
      itemPath = `${parentPath}${parentPath && '/'}${item.path}`;
    }

    if (item.children) {
      return (
        <SubMenu
          title={item.icon ? (<Icon type={item.icon} />) : item.name}
          key={itemPath}
        >
          {getNavMenuItems(pathname, item.children, itemPath)}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={itemPath}>
        {
          /^https?:\/\//.test(itemPath) ? (
            <a href={itemPath} target={item.target}>
              {item.icon && <img src={item.icon} alt="icon" />}
              <span>{item.name}</span>
            </a>
          ) : (
            <Link to={itemPath} target={item.target}>
              {item.icon && <Icon type={item.icon} />}
              <span>{item.name}</span>
            </Link>
          )
        }
      </Menu.Item>
    );
  });
}

function getCurrentMenuSelectedKeys(pathname) {
  let keys = pathname.split('/').slice(1);

  if (keys.length === 1) keys.push('/');

  keys = keys.reduce(
    (arr, current, index) => arr.concat(
      index
        ? `${arr[index - 1]}${arr[index - 1] && '/'}${current}`
        : `${current}`,
    ),
    [],
  );
  return keys;
}

function getDefaultCollapsedSubMenus(pathname) {
  const currentMenuSelectedKeys = getCurrentMenuSelectedKeys(pathname);
  return currentMenuSelectedKeys.slice(0, -1);
}
