import React from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import styles from './Page.module.less';

export default ({ className, sub, action, children, ...restProps }) => {
  const clsString = classNames(styles.page, className);

  return (
    <div className={clsString} {...restProps}>
      { sub ? (
        <div className={styles.header}>
          <div className={styles.goback} onClick={() => window.history.back()}><Icon type="left" /> 返回</div>
          <div className={styles.action}>{action}</div>
        </div>
      ) : <div className={styles.action}>{action}</div>}
      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  );
};
