import { list, get, create, update, remove, listRechargeLog, createRechargeLog } from '../services/app';

export default {
  name: 'app',
  state: {
    query: {},
    items: [],
    meta: {},
    data: {},
    logs: [],
    rechargeModalVisible: false,
  },
  reducers: {
    setState(state, payload) {
      return { ...state, ...payload };
    },
    setQuery(state, payload) {
      return { ...state, query: payload };
    },
  },
  effects: dispatch => ({
    async relist(payload, { app: { query: prevQuery } }) {
      const result = await dispatch.app.list({ ...prevQuery, ...payload });
      return result;
    },
    async list(payload = {}) {
      // 设置 query
      dispatch.app.setQuery(payload);

      // 发起请求
      const data = await list(payload);

      // 更新 state
      dispatch.app.setState({
        items: data.items,
        meta: data.meta,
      });

      return data;
    },
    async get(id) {
      const data = await get(id);
      dispatch.app.setState({ data });
      return data;
    },
    async create(payload) {
      const data = await create(payload);
      return data;
    },
    async update(payload) {
      const data = await update(payload.id, payload);
      return data;
    },
    async remove(payload) {
      const data = await remove(payload);
      return data;
    },
    async listRechargeLog(aid) {
      const data = await listRechargeLog(aid);
      dispatch.app.setState({ logs: data.items });
      return data;
    },
    async createRechargeLog(payload) {
      const data = await createRechargeLog(payload);
      return data;
    },
  }),
};
