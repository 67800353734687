import React from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { Button, Popconfirm, Divider } from 'antd';
import Page from '../../layouts/Page';
import { mapMetaToPagination, mapPaginationToQuery } from '../../utils/helper';
import Table from './Table';

class ServerList extends React.PureComponent {
  componentDidMount() {
    const { list, relist } = this.props;
    (!window.isPop ? list : relist)();
  }

  handlePaginationChange = (pagination, filters) => {
    const { relist } = this.props;
    relist({
      ...mapPaginationToQuery(pagination),
      ...filters,
    });
  }

  remove = async (id) => {
    const { remove, relist } = this.props;
    await remove(id);
    relist();
  }

  renderOperation = id => (
    <div>
      <Link to={`${id}`}>查看</Link>
      <Divider type="vertical" />
      <Link to={`${id}/update`}>修改</Link>
      <Divider type="vertical" />
      <Popconfirm title="确定要删除吗？" onConfirm={() => this.remove(id)}>
        <a href="#">删除</a>
      </Popconfirm>
    </div>
  )

  render() {
    const { loading, dataSource, pagination, update, relist } = this.props;

    const tableProps = {
      loading,
      dataSource,
      pagination,
      onChange: this.handlePaginationChange,
      renderOperation: this.renderOperation,
      update: async (...args) => {
        await update(...args);
        relist();
      },
    };


    return (
      <Page>
        <div className="mb20"><Link to="create"><Button type="primary">新增</Button></Link></div>
        <Table {...tableProps} />
      </Page>
    );
  }
}

export default connect(
  state => ({
    loading: state.loading.effects.server.list,
    query: state.server.query,
    dataSource: state.server.items,
    pagination: mapMetaToPagination(state.server.meta),
  }),
  dispatch => ({
    list: dispatch.server.list,
    relist: dispatch.server.relist,
    update: dispatch.server.update,
    remove: dispatch.server.remove,
  }),
)(ServerList);
