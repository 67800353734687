import React from 'react';
import { connect } from 'react-redux';
import { Router, Link } from '@reach/router';
import { Layout, Avatar, BackTop, Popconfirm } from 'antd';
import { ContainerQuery } from 'react-container-query';
import DocumentTitle from 'react-document-title';
import classnames from 'classnames';
import { find, omit } from 'lodash';
import Exception from '../components/Exception';
import BasicSider from './BasicSider';
import { PRODUCT_NAME } from '../constant';
import { getRouteData, getMenus } from '../nav';
import storage from '../storage';

const { Content } = Layout;

const query = {
  'screen-xs': { maxWidth: 575 },
  'screen-sm': { minWidth: 576, maxWidth: 767 },
  'screen-md': { minWidth: 768, maxWidth: 991 },
  'screen-lg': { minWidth: 992, maxWidth: 1199 },
  'screen-xl': { minWidth: 1200 },
};

class BasicLayout extends React.PureComponent {
  componentDidMount() {
    const { init } = this.props;
    init();
  }

  componentWillUnmount() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
  }

  getPageTitle(pathname) {
    const route = find(this.routes, { path: pathname === '/' ? pathname : pathname.substr(1) });
    return route ? `${route.name} - ${PRODUCT_NAME}` : PRODUCT_NAME;
  }

  render() {
    const { user } = storage.get('auth') || {};
    const { location: { pathname }, logout } = this.props;
    const menus = getMenus('BasicLayout')(user.groupId);
    const routes = getRouteData('BasicLayout')(user.groupId);

    const siderProps = {
      menus,
    };

    return (
      <DocumentTitle title={this.getPageTitle(pathname)}>
        <ContainerQuery query={query}>
          {params => (
            <Layout className={classnames(params)} hasSider>
              <BasicSider {...siderProps} pathname={pathname} />
              <Layout>
                <Popconfirm title="是否退出？" onConfirm={logout} placement="bottomRight">
                  <Avatar className="absolute right m15" size="large">{user.name}</Avatar>
                </Popconfirm>
                <Content style={{ minHeight: '100vh', backgroundColor: '#fff' }}>
                  <Router>
                    {routes.map(item => (
                      <item.component key={item.path} {...omit(item, ['component'])} />
                    ))}
                    <Exception type="500" path="500" linkElement={Link} style={{ height: '100vh' }} />
                    <Exception type="403" path="403" linkElement={Link} style={{ height: '100vh' }} />
                    <Exception type="404" default linkElement={Link} style={{ height: '100vh' }} />
                  </Router>
                  <BackTop />
                </Content>
              </Layout>
            </Layout>
          )}
        </ContainerQuery>
      </DocumentTitle>
    );
  }
}

export default connect(
  state => ({
    authed: state.auth.authed,
  }),
  dispatch => ({
    logout: dispatch.auth.logout,
    init: dispatch.auth.init,
  }),
)(BasicLayout);
