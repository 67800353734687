import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Tabs, Button, Icon, Checkbox, Row, Col } from 'antd';
import logo from '../../assets/logo.svg';
import styles from './index.module.less';
import services from '../../services';
import { regMobile } from '../../utils/validate';

const FormItem = Form.Item;
const { TabPane } = Tabs;

class Login extends Component {
  state = { count: 0, type: 'account' }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  handleSwitch = (key) => {
    this.setState({ type: key });
  }

  handleCaptchaGet = async (mobile) => {
    const { form: { setFieldsValue } } = this.props;
    const data = await services.auth.captcha({ mobile });

    setFieldsValue(data);
    let count = 59;
    this.setState({ count });
    this.interval = setInterval(() => {
      count -= 1;
      this.setState({ count });
      if (count === 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form: { validateFields }, login } = this.props;
    validateFields((errors, values) => {
      if (errors) return;
      login(values);
    });
  }

  render() {
    const { form: { getFieldDecorator, getFieldValue }, loading } = this.props;
    const { count, type } = this.state;

    return (
      <div className={styles.main}>
        <Form className={styles.form} onSubmit={this.handleSubmit}>
          <div className={styles.top}>
            <img alt="Logo" className={styles.logo} src={logo} />
          </div>
          <Tabs
            className={styles.tabs}
            activeKey={type}
            onChange={this.handleSwitch}
          >
            <TabPane tab="账户密码登录" key="account">
              <FormItem>
                {getFieldDecorator('mobile', {
                  rules: [{ required: type === 'account', message: '请输入账户名！' }],
                })(
                  <Input
                    size="large"
                    prefix={<Icon type="user" className={styles.prefixIcon} />}
                    placeholder="账户名"
                  />,
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: type === 'account', message: '请输入密码！' }],
                })(
                  <Input
                    size="large"
                    prefix={<Icon type="lock" className={styles.prefixIcon} />}
                    type="password"
                    placeholder="密码"
                  />,
                )}
              </FormItem>
            </TabPane>
            <TabPane tab="手机号登录" key="mobile">
              <FormItem>
                {getFieldDecorator('mobile', {
                  rules: [
                    { required: type === 'mobile', message: '请输入手机号！' },
                    { pattern: regMobile, message: '手机号格式错误！' },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={<Icon type="mobile" className={styles.prefixIcon} />}
                    placeholder="手机号"
                  />,
                )}
              </FormItem>
              <FormItem>
                <Row gutter={8}>
                  <Col span={16}>
                    {getFieldDecorator('captcha', {
                      rules: [{ required: type === 'mobile', message: '请输入验证码！' }],
                    })(
                      <Input
                        size="large"
                        prefix={<Icon type="mail" className={styles.prefixIcon} />}
                        placeholder="验证码"
                      />,
                    )}
                  </Col>
                  <Col span={8}>
                    <Button
                      ghost
                      type="primary"
                      disabled={count}
                      className={styles.getCaptcha}
                      size="large"
                      onClick={() => getFieldValue('mobile') && this.handleCaptchaGet(getFieldValue('mobile'))}
                    >
                      {count ? `${count} s` : '获取验证码'}
                    </Button>
                  </Col>
                </Row>
              </FormItem>
              {getFieldDecorator('captchaId')(<Input type="hidden" />)}
            </TabPane>
          </Tabs>
          <FormItem className={styles.additional}>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox className={styles.autoLogin}>自动登录</Checkbox>)}
            <Button size="large" loading={loading} className={styles.submit} type="primary" htmlType="submit">
              登录
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default Form.create()(
  connect(
    state => ({
      loading: state.loading.effects.auth.login,
    }),
    dispatch => ({
      login: dispatch.auth.login,
    }),
  )(Login),
);
