// pagination
export const defaultPagination = {
  showSizeChanger: true,
  showQuickJumper: true,
  hideOnSinglePage: true,
  showTotal: total => `共 ${total} 条`,
  pageSizeOptions: ['10', '20', '50', '100'],
  current: 1,
  total: null,
  pageSize: 20,
};

export function mapMetaToPagination(meta) {
  return {
    ...defaultPagination,
    current: meta.page + 1,
    total: meta.total,
    pageSize: meta.size,
  };
}

export function mapPaginationToQuery(pagination) {
  return {
    page: pagination.current > defaultPagination.current ? pagination.current - 1 : undefined,
    size: pagination.pageSize !== defaultPagination.pageSize ? pagination.pageSize : undefined,
  };
}

// mapFileListToValues
export function mapFileListToString(fileList) {
  return fileList
    .filter(file => file.response)
    .map(file => file.response)
    .map(item => item.name)
    .join(',');
}

// enumToArray
export function enumToArray(obj = {}, inner = false) {
  return Object.keys(obj).map(item => (!inner ? {
    value: item,
    text: obj[item],
  } : {
    value: item,
    ...obj[item],
  }));
}

// delay
export function delay(timeout) {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}
