import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, connect } from 'react-redux';
import { Router, createHistory } from '@reach/router';

import { LocaleProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import store from './store';
import registerServiceWorker, {unregister} from './registerServiceWorker';

import BasicLayout from './layouts/BasicLayout';
import AuthLayout from './layouts/AuthLayout';

import './index.less';
import './polyfill';

moment.locale('zh-cn');

// 判断返回和前进事件
let isPop = false;
createHistory(window).listen(() => { isPop = true; });
Object.defineProperty(window, 'isPop', {
  get() {
    setTimeout(() => { isPop = false; });
    return isPop;
  },
});

const Routes = connect(({ auth }) => ({ authed: auth.authed }))(
  ({ authed }) => (
    <Router>
      {authed ? <BasicLayout path="/*" /> : <AuthLayout path="/*" />}
    </Router>
  ),
);

ReactDOM.render(
  <Provider store={store}>
    <LocaleProvider locale={zhCN}>
      <Routes />
    </LocaleProvider>
  </Provider>,
  document.getElementById('root'),
);

//registerServiceWorker();
unregister();